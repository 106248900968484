import { Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Investigation from "../pages/Investigation";
import User from "../pages/User";
import ResetPassword from "../pages/AuthenticationInner/ResetPassword";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import Login from "pages/Authentication/login";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import Logout from "pages/Authentication/Logout";

const authProtectedRoutes = [
  { path: "/dashboard/:id?", component: <Dashboard /> },
  { path: "/investigation/:id", component: <Investigation /> },
  { path: "/user", component: <User /> },
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
  { path: "/reset-password", component: <ResetPassword /> },
];
export { authProtectedRoutes, publicRoutes };
