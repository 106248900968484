import React, { Fragment, useEffect, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import { Table, Row, Col, Button, FormGroup, FormFeedback } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import { Link } from "react-router-dom";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import searchIcon from "../../assets/images/search.svg";
import calendorIcon from "../../assets/images/calendor.svg";
import { useSearchParams } from "react-router-dom";
import Spinners from "./Spinner";
import { debounce } from "lodash";

interface filter {
  handleFilter: (payload: any) => void;
  setFilterOptions: any;
  debouncedSearch: (value) => void;
}

interface TableContainerProps {
  defaultFilters: any;
  setdefaultFilters: any;
  metaData: any;
  isLoading: boolean;
  handleFilter?: any;
  columns?: any;
  data: any;
  tableClass?: string;
  theadClass?: string;
  isShowingPageLength?: boolean;
  paginationDiv?: string;
  pagination?: string;
}

// Define a default UI for filtering
function GlobalFilter({
  handleFilter,
  setFilterOptions,
  debouncedSearch,
}: filter) {
  const [_, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = React.useState("");

  const validation: any = useFormik({
    initialValues: {
      startdate: "",
      enddate: "",
    },
    validationSchema: Yup.object({
      startdate: Yup.string().required("Please Enter Your Start Date"),
      enddate: Yup.string().required("Please Enter Your End Date"),
    }),
    onSubmit: (values: any) => {
      // console.log(values);
    },
  });
  const [selectedStatus, setSelectedStatus] = useState<any>(undefined);
  const options = [
    { label: "Select Level", value: "undefined" },
    { label: "Review - L1", value: "L1" },
    { label: "Pending - L1", value: "P1" },
    { label: "Pending - L2", value: "P2" },
    { label: "Escalation - L2", value: "E2" },
    { label: "SAR", value: "SAR" },
    { label: "False Positive", value: "FP" },
  ];
  const Handleselect = (e) => {
    setSelectedStatus(e.target.value);
  };

  return (
    <React.Fragment>
      <Col md={3} className="serachinput">
        <img src={searchIcon} alt="icon" />
        <input
          type="search"
          className="form-control"
          id="search-bar-0"
          value={searchValue || ""}
          placeholder="Search"
          onChange={(e) => {
            setSearchValue(e.target.value);
            debouncedSearch(e.target.value);
          }}
        />
      </Col>

      <Col md={9}>
        <FormGroup className="mb-4" row>
          <Col md={12}>
            <Row className="justify-content-end">
              <Col md={4}>
                <div className="form-group">
                  <select
                    value={selectedStatus}
                    onChange={Handleselect}
                    className="form-select"
                  >
                    {options.map((option: any, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md={3} className="calendoricon">
                <img src={calendorIcon} alt="calendorIcon" />
                <Flatpickr
                  className="form-control d-block"
                  id="orderdate"
                  name="startdate"
                  placeholder="Start date"
                  options={{
                    mode: "single",
                    dateFormat: "d M, Y",
                  }}
                  onChange={(customerdate: any) => {
                    console.log(customerdate, "dateee");

                    validation.setFieldValue("startdate", customerdate[0]);
                  }}
                  value={validation.values.startdate || ""}
                />
                {validation.errors.startdate && validation.touched.startdate ? (
                  <FormFeedback type="invalid">
                    {validation.errors.startdate}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col md={3} className="calendoricon">
                <img src={calendorIcon} alt="calendorIcon" />
                <Flatpickr
                  className="form-control d-block"
                  id="orderdate"
                  name="enddate"
                  placeholder="End date"
                  options={{
                    mode: "single",
                    dateFormat: "d M, Y",
                  }}
                  onChange={(customerdate: any) =>
                    validation.setFieldValue("enddate", customerdate[0])
                  }
                  value={validation.values.enddate || ""}
                />
                {validation.errors.enddate && validation.touched.enddate ? (
                  <FormFeedback type="invalid">
                    {validation.errors.enddate}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col md={2} className="pl-0">
                <Button
                  onClick={() => {
                    const payload = {
                      startdate: validation.values.startdate
                        ? moment(validation.values.startdate).format(
                            "YYYY-MM-DD"
                          )
                        : undefined,
                      enddate: validation.values.enddate
                        ? moment(validation.values.enddate).format("YYYY-MM-DD")
                        : undefined,
                      status: selectedStatus,
                      search: searchValue,
                      page: 1,
                    };

                    // const encodedFilter = btoa(JSON.stringify(payload));
                    // setSearchParams({ f: encodedFilter });
                    setFilterOptions(payload);
                    handleFilter(payload);
                  }}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Col>
        </FormGroup>
      </Col>
    </React.Fragment>
  );
}

function SortedHead({ children, handleSortMethod }) {
  const [sorted, setSorted] = useState(false);
  return (
    <div
      onClick={() => {
        setSorted(!sorted);
        handleSortMethod(!sorted ? "DESC" : "ASC");
      }}
      style={{ cursor: "pointer", display: "flex" }}
    >
      {children}
      <Col xl={3} lg={4} sm={6}>
        <div className="arrow-icon">
          {sorted ? (
            <i className="bx bx-down-arrow-alt"></i>
          ) : (
            <i className="bx bx-up-arrow-alt"></i>
          )}
        </div>
      </Col>
    </div>
  );
}

const TableContainer: any = ({
  defaultFilters,
  setdefaultFilters,
  metaData,
  isLoading,
  handleFilter,
  columns,
  data,
  tableClass,
  theadClass,
  isShowingPageLength,
  paginationDiv,
  pagination,
}: TableContainerProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  }: any = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: 150,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  // const [defaultFilters, setdefaultFilters] = useState<any>(defaultFilters);
  const canPreviousPage = defaultFilters.page !== 1;
  const canNextPage = defaultFilters.page < metaData.total_pages;

  const previousPage = () => {
    const payload = { ...defaultFilters, page: defaultFilters.page - 1 };
    setdefaultFilters(payload);
    handleFilter(payload);
  };

  const nextPage = () => {
    const payload = { ...defaultFilters, page: defaultFilters.page + 1 };
    setdefaultFilters(payload);
    handleFilter(payload);
  };

  const gotoPage = (item) => {
    const payload = { ...defaultFilters, page: item };
    setdefaultFilters(payload);
    handleFilter(payload);
  };

  const delayedSearch = debounce((value) => {
    const payload = { ...defaultFilters, search: value, page: 1 };
    setdefaultFilters(payload);
    handleFilter(payload);
  }, 800);

  const handleSort = (name, method) => {
    const payload = {
      ...defaultFilters,
      sortby: name,
      sortmethod: method,
      page: 1,
    };
    setdefaultFilters(payload);
    handleFilter(payload);
  };
  console.log(Array.from(Array(metaData.total_pages)));

  return (
    <Fragment>
      <Row className="mb-4">
        <GlobalFilter
          debouncedSearch={delayedSearch}
          handleFilter={handleFilter}
          setFilterOptions={setdefaultFilters}
        />
      </Row>
      <>
        <div className="table-responsive" style={{ minHeight: "200px" }}>
          <Table hover {...getTableProps()} className={tableClass}>
            <thead className={theadClass}>
              {headerGroups.map((headerGroup: any) => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      key={column.id}
                      className={`${column.isSortable ? "sorting_asc" : ""}`}
                    >
                      <div
                        // onClick={() => handleSort(column)}
                        className="cursor-pointer"
                      >
                        <SortedHead
                          handleSortMethod={(method) =>
                            handleSort(column.id, method)
                          }
                        >
                          {column.render("Header")}
                        </SortedHead>
                      </div>
                      <Filter column={column} />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            {isLoading ? (
              <div className="spinner-container">
                <Spinners setLoading={() => null} />
              </div>
            ) : page.length ? (
              <tbody {...getTableBodyProps()}>
                {page.map((row: any) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr>
                        {row.cells.map((cell: any) => {
                          const alertId = cell.row.original.ALERT_ID;
                          return (
                            <td
                              key={cell.id}
                              {...cell.getCellProps()}
                              onClick={() => {
                                sessionStorage.setItem(
                                  "filter",
                                  JSON.stringify(defaultFilters)
                                );
                              }}
                            >
                              <Link to={`/investigation/${alertId}`}>
                                {cell.render("Cell")}
                              </Link>
                            </td>
                          );
                        })}
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <tr className="position-relative">
                  <p
                    style={{
                      position: "absolute",
                      top: "0",
                      width: "100%",
                      color: "black",
                      textAlign: "center",
                      padding: "50px 0",
                    }}
                  >
                    No Records Found
                  </p>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
        <Row className="justify-content-between align-items-center">
          {isShowingPageLength && (
            <div className="col-sm">
              <div className="text-muted">
                Showing <span className="fw-semibold">{page.length}</span> of{" "}
                <span className="fw-semibold">{data.length}</span> entries
              </div>
            </div>
          )}
          <div className={paginationDiv}>
            <ul className={pagination}>
              <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`}>
                <Link to="#" className="page-link" onClick={previousPage}>
                  <i className="mdi mdi-chevron-left"></i>
                </Link>
              </li>
              {Array.from(Array(metaData.total_pages).keys()).map(
                (item: any, key: number) => (
                  <React.Fragment key={key}>
                    <li
                      className={
                        defaultFilters.page === item + 1
                          ? "page-item active"
                          : "page-item"
                      }
                    >
                      <Link
                        to="#"
                        className="page-link"
                        onClick={() => gotoPage(item + 1)}
                      >
                        {item + 1}
                      </Link>
                    </li>
                  </React.Fragment>
                )
              )}
              <li className={`page-item ${!canNextPage ? "disabled" : ""}`}>
                <Link to="#" className="page-link" onClick={nextPage}>
                  <i className="mdi mdi-chevron-right"></i>
                </Link>
              </li>
            </ul>
          </div>
        </Row>
      </>
    </Fragment>
  );
};

export default TableContainer;
