import React, { useEffect, useState } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "Components/Common/TableContainer";
import "flatpickr/dist/themes/material_blue.css";
import Breadcrumbs from "Components/Common/Breadcrumb";
import { DateCell, Id, RoleLevel, Status } from "./LatestTransactions";
import { Col, Row, Card, CardBody } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { Cookies } from "react-cookie";
import withRouter from "Components/Common/withRouter";
import axiosApi from "../../helpers/axiosApi";
import AuthProtected from "Routes/AuthProtected";

function Dashboard(props) {
  //meta title
  document.title = "Rakuten Europe Bank";
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [meta, setMeta] = useState<any>([]);
  const [hasError, setError] = useState<any>(false);
  const [defaultFilters, setdefaultFilters] = useState({ page: 1 });

  const columns = [
    {
      Header: "Serial No.",
      accessor: "id",
      width: "150px",
      style: {
        textAlign: "center",
        width: "10%",
        background: "#0000",
      },
      filterable: true,
      Filter: false,
      Cell: (cellProps: any) => {
        return <Id {...cellProps} />;
      },
    },
    ,
    {
      Header: "Shop ID",
      accessor: "USER_ACCOUNT_ID",
      width: "150px",
      style: {
        textAlign: "center",
        width: "10%",
        background: "#0000",
      },
      filterable: true,
      Filter: false,
      Cell: (cellProps: any) => {
        return <Id {...cellProps} />;
      },
    },
    {
      Header: "Alert ID",
      accessor: "ALERT_ID",
      filterable: true,
      Filter: false,
      Cell: (cellProps: any) => {
        return <Id {...cellProps} />;
      },
    },
    {
      Header: "Creation date",
      accessor: "ALERTED_ON",
      filterable: true,
      Filter: false,
      Cell: (cellProps: any) => {
        return <DateCell {...cellProps} />;
      },
    },
    {
      Header: "Status",
      accessor: "ALERT_STATUS",
      filterable: true,
      Filter: false,
      Cell: (cellProps: any) => {
        return <Status {...cellProps} />;
      },
    },
    {
      Header: "Rule",
      accessor: "RULE_ID",
      filterable: true,
      Filter: false,
      Cell: (cellProps: any) => {
        return <Id {...cellProps} />;
      },
    },
    {
      Header: "Last modification",
      accessor: "updatedAt",
      Filter: false,
      Cell: (cellProps: any) => {
        return <DateCell {...cellProps} />;
      },
    },
    {
      Header: "Role level",
      accessor: "ROLE_LEVEL",
      Filter: false,
      Cell: (cellProps) => {
        return <RoleLevel {...cellProps} />;
      },
    },
  ];

  const fetchAlerts = async (payload?: any, nocache: Boolean = false) => {
    setData([]);
    const pageNo = payload?.page ?? 1;
    let query = `?page=${pageNo}`;

    if (payload?.startdate) {
      query += `&start_date=${payload.startdate}`;
    }

    if (payload?.enddate) {
      query += `&end_date=${payload.enddate}`;
    }

    if (payload?.status) {
      query += `&status=${payload.status}`;
    }

    if (payload?.search) {
      query += `&search=${payload.search}`;
    }

    if (payload?.sortby) {
      query += `&sortby=${payload.sortby}`;
    }

    if (payload?.sortmethod) {
      query += `&sortmethod=${payload.sortmethod}`;
    }

    if (nocache) {
      query += `&nocache=${nocache}`;
    }

    sessionStorage.clear();

    const apiUrl = `${process.env.REACT_APP_API_URL}/alerts${query}`;

    setLoading(true);
    try {
      const cookies = new Cookies();
      const loggedInuser = cookies.get("token");
      const apiData = await axiosApi.get(`${apiUrl}`, {
        headers: {
          Authorization: loggedInuser,
        },
      });
      if (apiData.data) {
        setError(false);
        setData(apiData.data.data);
        setMeta(apiData.data.meta);

        if (!apiData.data.data.length) {
          setLoading(false);
        }

        if (apiData.data.data.length) {
          setLoading(false);
        }
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        props.router.navigate("/login");
      }
      setLoading(false);
      setData([]);
      setError(true);
      toast.error(error?.response?.data?.error);
    }
  };

  useEffect(() => {
    const shouldCache = sessionStorage.getItem("cache") ? true : false;
    const filterOptions = sessionStorage.getItem("filter");
    if (typeof filterOptions === "string") {
      setdefaultFilters(JSON.parse(filterOptions));
      fetchAlerts(JSON.parse(filterOptions), shouldCache);
    } else {
      fetchAlerts(null, shouldCache);
    }
  }, []);

  return (
    <React.Fragment>
      <AuthProtected>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs breadcrumbItem="Dashboard" hideItems={true} />
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <TableContainer
                      defaultFilters={defaultFilters}
                      setdefaultFilters={setdefaultFilters}
                      metaData={meta}
                      isLoading={isLoading}
                      handleFilter={(payload) => fetchAlerts(payload)}
                      columns={columns}
                      data={data}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination justify-content-end pagination-rounded mb-2 flex-wrap"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <ToastContainer position="top-right" autoClose={2000} />
      </AuthProtected>
    </React.Fragment>
  );
}
export default withRouter(Dashboard);
