import { userForgetPasswordSuccess, userForgetPasswordError } from "./reducer";

//Include Both Helper File with needed methods

import axios from "axios";
import axiosApi from "../../../helpers/axiosApi";

export const userForgetPassword = (user, history) => async (dispatch) => {
  const baseUrl = process.env.REACT_APP_API_URL;
  try {
    const response = await axiosApi.post(`${baseUrl}/forgot-password`, {
      email: user.email,
    });

    if (response) {
      dispatch(
        userForgetPasswordSuccess("Reset link has sent to your mailbox.")
      );
    }
  } catch (forgetError: any) {
    dispatch(userForgetPasswordError(forgetError?.response?.data?.error));
  }
};
