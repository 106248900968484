import React, { useEffect, useState } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "flatpickr/dist/themes/material_blue.css";
import Breadcrumbs from "Components/Common/Breadcrumb";
import Select from "react-select";
import { Button, Col, Row, Form, Input, Card, CardBody } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import { ToastContainer, toast } from "react-toastify";
import { Cookies } from "react-cookie";
import withRouter from "Components/Common/withRouter";
import moment from "moment";
import axiosApi from "../../helpers/axiosApi";

function Investigation(props) {
  //meta title
  document.title = "Investigation | Rakuten Europe Bank";
  const cookies = new Cookies();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");
  const [alertId, setAlertId] = useState<string>("undefined");
  const [data, setData] = useState<any>([]);
  const [hasPartialAccess, setPartialAccess] = useState(false);

  const [selectedGroup, setselectedGroup] = useState<any>(null);
  const [optionGroups, setOptionGroups] = useState<any>(null);

  function handleSelectGroup(selectedGroup: any) {
    setselectedGroup(selectedGroup);
  }

  const fetchInvestigation = async (id: string) => {
    try {
      const cookies = new Cookies();
      const loggedInuser = cookies.get("token");
      const apiData = await axiosApi.get(
        process.env.REACT_APP_API_URL + "/investigation/" + id,
        {
          headers: {
            Authorization: loggedInuser,
          },
        }
      );

      if (apiData.data) {
        setData(apiData.data.data);

        if (apiData.data.partialAccess) setPartialAccess(true);
        else setPartialAccess(false);

        setOptionGroups([{ options: apiData.data.statusOptions }]);

        setTimeout(() => {
          setLoading(false);
        }, 800);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      setLoading(false);
      if (error?.response?.status === 401) {
        localStorage.removeItem("authUser");
        props.router.navigate("/login");
      } else {
        console.log(error);
        setTimeout(() => {
          props.router.navigate("/dashboard");
        }, 2000);
      }
    }
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    const tempAlertId = pathname.split("/").pop()?.toString();

    if (tempAlertId) {
      setAlertId(tempAlertId);
      fetchInvestigation(tempAlertId);
    }
  }, []);

  const handleSubmit = async () => {
    const token = cookies.get("token");
    try {
      const data = await axiosApi.post(
        process.env.REACT_APP_API_URL + "/change-status/" + alertId,
        {
          alertStatus: selectedGroup.value,
          note: note,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      if (data.data) {
        sessionStorage.setItem("cache", "true");
        props.router.navigate("/dashboard"); //change some data here
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  };

  const formatStatus = (value) => {
    switch (value) {
      case "L1":
        return "Review - L1";
      case "P1":
        return "Pending - L1";
      case "P2":
        return "Pending - L2";
      case "E2":
        return "Escalation - L2";
      case "SAR":
        return "SAR";
      case "FP":
        return "FALSE POSITIVE";

      default:
        break;
    }
  };

  const handleSkip = async () => {
    setData([]);
    setLoading(true);
    const token = cookies.get("token");
    const pathname = window.location.pathname;
    const tempAlertId = pathname.split("/").pop()?.toString();

    try {
      const apiData = await axiosApi.get(
        process.env.REACT_APP_API_URL + "/alert-skip/" + tempAlertId,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      if (apiData.data) {
        props.router.navigate(`/investigation/${apiData.data.ALERT_ID}`);
        fetchInvestigation(apiData.data.ALERT_ID);
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.error);
      setTimeout(() => {
        props.router.navigate("/dashboard");
      }, 2000);
      console.error(error);
    }
  };

  const getDateFormateMethod = (date: any) => {
    let today = moment(new Date(date)).utc().format("DD MMM Y, HH:mm A [GMT]");
    return today;
  };

  return (
    <React.Fragment>
      {isLoading || !data.USER_ACCOUNT_ID ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs
              link={"/dashboard"}
              title="Alerts"
              breadcrumbItem={`Investigation`}
              subTitle={hasPartialAccess ? "( READ ONLY )" : ""}
            />
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Row>
                <Col xs={12}>
                  <Card>
                    <CardBody>
                      <div className="invistigationMain">
                        <div className="Description">
                          <Form>
                            <div className="row">
                              <div className="col-2">
                                <div className="lable">Shop ID:</div>
                              </div>
                              <div className="col-10">
                                <div className="infoData">
                                  {data?.USER_ACCOUNT_ID}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-2">
                                <div className="lable">Alert ID:</div>
                              </div>
                              <div className="col-10">
                                <div className="infoData">{data?.ALERT_ID}</div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-2">
                                <div className="lable">Rule ID:</div>
                              </div>
                              <div className="col-10">
                                <div className="infoData">{data?.RULE_ID}</div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-2">
                                <div className="lable">Creation date:</div>
                              </div>
                              <div className="col-10">
                                <div className="infoData">
                                  {getDateFormateMethod(data?.createdAt)}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-2">
                                <div className="lable">Status:</div>
                              </div>
                              <div className="col-10">
                                <div className="infoData">
                                  {formatStatus(data?.ALERT_STATUS)}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-2">
                                <div className="lable">Rule triggered:</div>
                              </div>
                              <div className="col-10">
                                <div className="infoData">
                                  {data?.RULE_DESCRIPTION}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-2">
                                <div className="lable">Last modification:</div>
                              </div>
                              <div className="col-10">
                                <div className="infoData">
                                  {getDateFormateMethod(data?.updatedAt)}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-2">
                                <div className="lable">Role level:</div>
                              </div>
                              <div className="col-10">
                                <div className="infoData">
                                  {data?.ROLE_LEVEL ?? "L1"}
                                </div>
                              </div>
                            </div>
                            {data?.NOTES?.map((note) => {
                              const reviewer =
                                note?.user.status === "L1"
                                  ? "1st level"
                                  : note.user.status === "L2"
                                  ? "2nd level"
                                  : "1st level";
                              return (
                                <>
                                  <div className="row">
                                    <div className="col-2">
                                      <div className="lable">
                                        {`${reviewer} reviewer:`}
                                      </div>
                                    </div>
                                    <div className="col-10">
                                      <div className="infoData">
                                        {note?.user?.name}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-2"></div>
                                    <div className="col-5">
                                      <Input
                                        value={note.note}
                                        readOnly
                                        // tag="textarea"
                                        id="Note"
                                        rows={undefined}
                                        placeholder="Note"
                                        name="note"
                                      />
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                            {!hasPartialAccess ? (
                              <div className="row">
                                <div className="col-2">
                                  <div className="lable">
                                    Investigation note:
                                  </div>
                                </div>
                                <div className="col-5">
                                  <Input
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                    tag="textarea"
                                    id="Note"
                                    rows={3}
                                    placeholder="Note"
                                    name="note"
                                  />
                                </div>
                              </div>
                            ) : null}
                            {!hasPartialAccess ? (
                              <div className="row">
                                <div className="col-2">
                                  <div className="lable">Status:</div>
                                </div>
                                <div className="col-5">
                                  <div className="infoData">
                                    <Select
                                      value={selectedGroup}
                                      onChange={(s: any) => {
                                        handleSelectGroup(s);
                                      }}
                                      options={optionGroups}
                                      placeholder="Select"
                                      className="select2-selection"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="row">
                              <div className="col-2"></div>
                              <div className="col-10">
                                <div className="d-flex mt-3">
                                  <div className="infoData skipbtn">
                                    <Button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleSkip();
                                      }}
                                    >
                                      Skip
                                    </Button>
                                  </div>
                                  {!hasPartialAccess ? (
                                    <div className="infoData">
                                      <Button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (selectedGroup && note?.length) {
                                            handleSubmit();
                                          } else if (!selectedGroup) {
                                            toast.error(
                                              "Please update the status"
                                            );
                                          } else if (!note?.length) {
                                            toast.error(
                                              "Please provide a note"
                                            );
                                          }
                                        }}
                                      >
                                        Submit
                                      </Button>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  {data?.HISTORY?.length ? (
                    <Card>
                      <CardBody>
                        <div className="alfertInfo">
                          <div className="row mb-3">
                            <div className="col-3">
                              <div className="lable">Reviewer</div>
                            </div>
                            <div className="col-3">
                              <div className="lable">Investigation Status</div>
                            </div>
                            <div className="col-3">
                              <div className="lable">Note</div>
                            </div>
                            <div className="col-3">
                              <div className="lable">Modified Time</div>
                            </div>
                          </div>

                          {data?.HISTORY?.map((history) => (
                            <div className="row mb-2">
                              <div className="col-3">
                                <div className="infoData">
                                  <p>
                                    {`${history?.user?.name} `}
                                    <span
                                      style={{
                                        color: "grey",
                                        marginLeft: "10px",
                                      }}
                                    >{`( ${history?.user?.status} )`}</span>
                                  </p>
                                </div>
                              </div>

                              <div className="col-3">
                                <div className="infoData">
                                  <p>{formatStatus(history?.investigation)}</p>
                                </div>
                              </div>

                              <div className="col-3">
                                <div className="infoData">
                                  <p>{history?.note}</p>
                                </div>
                              </div>

                              <div className="col-3">
                                <div className="infoData">
                                  <p>{getDateFormateMethod(history?.time)}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </CardBody>
                    </Card>
                  ) : null}
                </Col>
              </Row>
            )}
          </div>
        </div>
      )}
      <ToastContainer position="top-right" autoClose={2000} />
    </React.Fragment>
  );
}
export default withRouter(Investigation);
