import axios from "axios";
import { Cookies } from "react-cookie";

const axiosApi = axios.create({});

axiosApi.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosApi;
