import React, { useEffect, useState } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "flatpickr/dist/themes/material_blue.css";
import Breadcrumbs from "Components/Common/Breadcrumb";
import { useSelector } from "react-redux";
import { Input, Col, Row, Card, CardBody, Form, Button } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Cookies } from "react-cookie";
import Modal from "react-bootstrap/Modal";
import axiosApi from "../../helpers/axiosApi";
import withUser from "Components/Common/withUser";
import DeleteModal from "Components/Common/DeleteModal";

function User() {
  document.title = "Rakuten Europe Bank";
  const baseUrl = process.env.REACT_APP_API_URL;
  interface Item {
    id: number;
    name: string;
    email: string;
    status: string;
  }
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [selectleval, setSelectlevel] = useState("");
  const options = ["Select Level", "L1", "L2", "ADMIN"];
  const [userdata, setuserData] = useState<Array<Item>>([]);
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [showmodal, setShowmodal] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [validUserName, setValidUserName] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<any>(undefined);

  const cookies = new Cookies();

  function validateEmail(email: string) {
    var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    var isValid = pattern.test(email);
    if (isValid) {
      setValidEmail(true);
      return true;
    } else {
      setValidEmail(false);
      return false;
    }
  }

  const Handleuser = (e) => {
    setValidUserName(false);
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^a-zA-Z\s]/g, "");
    setUsername(sanitizedValue);
  };
  const Handleemail = (e) => {
    const isValid = validateEmail(e.target.value);
    if (isValid) setValidEmail(true);
    setEmail(e.target.value);
  };
  const Handleselect = (e) => {
    setSelectlevel(e.target.value);
  };

  const submituser = (e) => {
    const isValidated = validateEmail(email);
    if (isValidated && username.trim().length) {
      const token = cookies.get("token");
      e.preventDefault();
      axiosApi
        .post(
          `${baseUrl}/sign-up`,
          {
            email: email,
            name: username,
            status: selectleval,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((resopnse) => {
          toast.success("User Added Successfully");
          fetchAllUsers();
        })
        .catch((error: any) => {
          console.log(error);
          toast.error(error?.response?.data?.error);
        });

      setUsername("");
      setEmail("");
      setSelectlevel("");
      handleClose();
    }
  };

  const fetchAllUsers = () => {
    const token = cookies.get("token");
    axiosApi
      .get(`${baseUrl}/all-users`, {
        headers: {
          Authorization: token,
        },
      })
      .then((resopnse) => {
        setuserData(resopnse.data.users);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.error);
        setLoading(false);
      });
  };

  const handleClose = () => {
    setShowmodal(false);
  };
  const handleShow = () => {
    setShowmodal(true);
  };

  const handleStatusChange = async (e, userId) => {
    try {
      const loggedInuser = localStorage.getItem("authUser");
      const user =
        typeof loggedInuser === "string" ? JSON.parse(loggedInuser) : {};
      if (user.status !== "ADMIN") return toast.error("You are not authorized");

      const adminToken = cookies.get("token");
      const data = await axiosApi.post(
        `${baseUrl}/change-user-status/${userId}`,
        {
          status: e.target.value,
        },
        {
          headers: {
            Authorization: adminToken,
          },
        }
      );
      if (data) {
        toast.success(data?.data?.message);
        console.log(data, "datata");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  };

  const deleteUser = async () => {
    try {
      const token = cookies.get("token");
      // userToDelete
      const data = await axiosApi.delete(
        `${baseUrl}/delete-user/${userToDelete.id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (data) {
        toast.success(data.data.message);
        fetchAllUsers();
        setOpen(false);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchAllUsers();
  }, []);

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setOpen(false)}
        onDeleteClick={deleteUser}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs hideItems={true} breadcrumbItem="Users" />

          {isLoading || !userdata.length ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <div className="userForm">
                      <Form>
                        <div className="row">
                          <div className="col-md-3"></div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 ">
                            <div className="form-group text-end">
                              {/* <Button onClick={submituser}>Submit</Button> */}
                              <Button variant="primary" onClick={handleShow}>
                                Add User
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                    <div className="table-responsive mt-4">
                      <table
                        role="table"
                        className="align-middle table-nowrap table-check table table-hover"
                      >
                        <thead className="table-light">
                          <tr role="row">
                            <th>
                              <div
                                title="Toggle SortBy"
                                style={{ cursor: "pointer" }}
                              >
                                Serial No.
                              </div>
                            </th>
                            <th>
                              <div
                                title="Toggle SortBy"
                                style={{ cursor: "pointer" }}
                              >
                                Name
                              </div>
                            </th>
                            <th>
                              <div
                                title="Toggle SortBy"
                                style={{ cursor: "pointer" }}
                              >
                                Email
                              </div>
                            </th>

                            <th>
                              <div
                                title="Toggle SortBy"
                                style={{ cursor: "pointer" }}
                              >
                                Role level
                              </div>
                            </th>
                            <th>
                              <div
                                title="Toggle SortBy"
                                style={{ cursor: "pointer" }}
                              >
                                Action
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody role="rowgroup">
                          {userdata?.map((user, i) => {
                            return (
                              <tr key={i}>
                                <td role="cell">{i + 1}</td>
                                <td role="cell">{user.name}</td>
                                <td role="cell">{user.email}</td>
                                <td role="cell">
                                  <select
                                    defaultValue={user.status}
                                    className="select-user"
                                    onChange={(e) =>
                                      handleStatusChange(e, user.id)
                                    }
                                  >
                                    <option value="L1">L1</option>
                                    <option value="L2">L2</option>
                                    <option value="ADMIN">Admin</option>
                                  </select>
                                </td>
                                <td role="cell">
                                  <i
                                    className="bx bxs-trash"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setUserToDelete(user);
                                      setOpen(true);
                                    }}
                                  ></i>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ToastContainer />
      <div>
        <Modal show={showmodal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="userForm">
              <Form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <Input
                        tag="input"
                        id="Name"
                        placeholder="Enter Name"
                        name="Name"
                        className="form-control"
                        value={username}
                        invalid={!username.trim().length && !validUserName}
                        type="text"
                        onChange={Handleuser}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <Input
                        tag="input"
                        id="Email"
                        placeholder="Enter Email"
                        name="Email"
                        className={`form-control ${
                          !validEmail ? "invalid-el" : ""
                        }`}
                        value={email}
                        invalid={!validEmail}
                        onChange={Handleemail}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <select
                        value={selectleval}
                        onChange={Handleselect}
                        className="form-select"
                      >
                        {options.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group text-center">
                      <Button
                        onClick={submituser}
                        disabled={
                          username.length < 1 ||
                          email.length < 1 ||
                          selectleval.length < 1
                        }
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
}
export default withUser(User);
