import Pages404 from "pages/Utility/pages-404";
import React from "react";
import { useSelector } from "react-redux";

function withUser(Component: any) {
  function ComponentWithRouterProp(props: any) {
    const LoginState = useSelector((state: any) => state.Login);
    const localUser = localStorage.getItem("authUser");
    const user = typeof localUser === "string" ? JSON.parse(localUser) : {};

    if (user?.status === "ADMIN" || LoginState.user?.status === "ADMIN")
      return <Component {...props} />;
    else return <Pages404 />;
  }
  return ComponentWithRouterProp;
}

export default withUser;
