import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "Components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../slices/thunk";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import { createSelector } from "reselect";
import axios from "axios";
import { resetForgetPasswordError } from "slices/auth/forgetpwd/reducer";
import logodark from "../../assets/images/logo-dark-white.svg";

const ForgetPasswordPage = (props) => {
  //meta title
  document.title = "Forget Password | Rakuten Europe Bank";

  const dispatch = useDispatch<any>();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      dispatch(userForgetPassword(values, props.history));
    },
  });

  const selectProperties = createSelector(
    (state: any) => state.ForgetPassword,
    (forgetPassword) => ({
      forgetError: forgetPassword.forgetError,
      forgetSuccessMsg: forgetPassword.forgetSuccessMsg,
    })
  );

  const { forgetError, forgetSuccessMsg } = useSelector(selectProperties);
  const [email, setEmail] = useState("");

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const resetSubmit = (e) => {
    e.preventDefault();
    validation.handleSubmit();
  };

  useEffect(() => {
    if (forgetError) {
      setTimeout(() => {
        dispatch(resetForgetPasswordError(null));
      }, 3000);
    }
  }, [forgetError]);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages  newLoginPage">
        <div className="container-fluid">
          <Row>
            <Col md={6}>
              <div className="LoginLeft">
                <div className="logo">
                  <img src={logodark} alt="logo" />
                </div>
                <h1>
                  <span>Welcome to</span> <br></br> Transaction <br></br>
                  Monitoring Tool
                </h1>
              </div>
            </Col>
            <Col md={6}>
              <Card className="overflow-hidden loginBox">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4 login">
                        <h5>Forgot Password</h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form className="form-horizontal">
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          // onChange={handleChangeEmail}
                          value={validation.values.email}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-center">
                          <button
                            className="btn btn-secondary"
                            onClick={resetSubmit}
                          >
                            Reset
                          </button>
                          <div className="mt-4 text-center mb-0">
                            Go back to{" "}
                            <Link to="/login" className="font-weight-medium">
                              Login
                            </Link>{" "}
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="allrights">© Rakuten Europe Bank</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ForgetPasswordPage);
