import {
  loginSuccess,
  apiError,
  logoutUserSuccess,
  resetLoginFlag,
} from "./reducer";
import axios from "axios";
import { Cookies } from "react-cookie";
import axiosApi from "../../../helpers/axiosApi";

export const loginuser = (user: any, history: any) => async (dispatch: any) => {
  const baseUrl = process.env.REACT_APP_API_URL;
  const cookies = new Cookies();

  try {
    const response = await axiosApi.post(`${baseUrl}/sign-in`, {
      email: user.email,
      password: user.password,
    });
    localStorage.setItem("authUser", JSON.stringify(response.data));
    dispatch(loginSuccess(response.data));

    cookies.set("token", response.data.token);
    history("/dashboard");
  } catch (error: any) {
    console.log(error, "erroorr");
    dispatch(apiError(error?.response?.data?.error));
  }
};

export const logoutUser = () => async (dispatch: any) => {
  try {
    localStorage.removeItem("authUser");
    dispatch(logoutUserSuccess(true));
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginMsgFlag = () => {
  try {
    const response = resetLoginFlag();
    return response;
  } catch (error) {
    return error;
  }
};

export const socialLogin =
  (type: any, history: any) => async (dispatch: any) => {
    try {
      let response: any;

      const socialdata = await response;
      if (socialdata) {
        sessionStorage.setItem("authUser", JSON.stringify(socialdata));
        dispatch(loginSuccess(socialdata));
        history("/dashboard");
      }
    } catch (error) {
      dispatch(apiError(error));
    }
  };
