import moment from "moment";
import React from "react";

const getDateFormateMethod = (date: any) => {
  // moment.tz.setDefault("IST");
  let today = moment(new Date(date)).utc().format("DD MMM Y, HH:mm A [GMT]");
  return today;
};

const DateCell = (cell: any) => {
  const date = getDateFormateMethod(cell.value);
  return date ? date : "";
};

const Status = (cell: any) => {
  let value;
  switch (cell.value || "L1") {
    case "L1":
      value = (
        <span className="badge-soft-warning bg-secondary">Review - L1</span>
      );
      break;
    case "P1":
      value = (
        <span className="badge-soft-danger bg-secondary">Pending - L1</span>
      );
      break;
    case "P2":
      value = (
        <span className="badge-soft-danger bg-secondary">Pending - L2</span>
      );
      break;
    case "E2":
      value = (
        <span className="badge-soft-danger bg-secondary">Escalation - L2</span>
      );
      break;
    case "SAR":
      value = <span className="badge-soft-success bg-secondary">SAR</span>;
      break;
    case "FP":
      value = (
        <span className="badge-soft-success bg-secondary">False Positive</span>
      );
      break;

    default:
      break;
  }
  return value ? value : "";
};

const Id = (cell: any) => {
  return cell.value ? cell.value : "";
};

const RoleLevel = (cell: any) => {
  return <span>{cell.value ?? "L1"}</span>;
};
export { DateCell, Id, Status, RoleLevel };
