import React, { useEffect, useRef, useCallback } from "react";
//Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";

import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import withRouter from "../../Components/Common/withRouter";
import { useSelector } from "react-redux";

const SidebarContent = (props: any) => {
  const ref = useRef<any>();
  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname;
    let matchingMenuItem = null;
    const ul: any = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  const LoginState = useSelector((state: any) => state.Login);
  const localUser = localStorage.getItem("authUser");
  const user = typeof localUser === "string" ? JSON.parse(localUser) : {};

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Menu")} </li> */}
            <li>
              <Link to="/dashboard">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <g id="dashboard" transform="translate(-38 -156)">
                    <rect
                      id="Rectangle_218"
                      data-name="Rectangle 218"
                      width="20"
                      height="20"
                      transform="translate(38 156)"
                      fill="#636363"
                      opacity="0"
                    />
                    <g
                      id="Group_551"
                      data-name="Group 551"
                      transform="translate(15295.986 -11326.896)"
                    >
                      <path
                        id="Union_1"
                        data-name="Union 1"
                        d="M-100.986-23.1a1,1,0,0,1-1-1v-3a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1Zm-9,0a1,1,0,0,1-1-1v-6a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1v6a1,1,0,0,1-1,1Zm9-7a1,1,0,0,1-1-1v-6a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1v6a1,1,0,0,1-1,1Zm-9-3a1,1,0,0,1-1-1v-3a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1Z"
                        transform="translate(-15145 11523)"
                        fill="#636363"
                      />
                    </g>
                  </g>
                </svg>

                <span>{props.t("Dashboard")}</span>
              </Link>
              {/* <ul className="sub-menu">
                <li>
                  <Link to="/dashboard">{props.t("Default")}</Link>
                </li>
                <li>
                  <Link to="/dashboard-saas">{props.t("Saas")}</Link>
                </li>
                <li>
                  <Link to="/dashboard-crypto">{props.t("Crypto")}</Link>
                </li>
                <li>
                  <Link to="/dashboard-blog">{props.t("Blog")}</Link>
                </li>
                <li>
                  <Link to="/dashboard-job">
                    <span className="badge rounded-pill text-bg-success float-end" key="t-new">New</span>
                    {props.t("Jobs")}
                  </Link>
                </li>
              </ul> */}
            </li>
            {user?.status === "ADMIN" || LoginState.user?.status === "ADMIN" ? (
              <li>
                <Link to="/User">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <g id="user" transform="translate(-812 -250)">
                      <rect
                        id="Rectangle_240"
                        data-name="Rectangle 240"
                        width="20"
                        height="20"
                        transform="translate(812 250)"
                        fill="#636363"
                        opacity="0"
                      />
                      <g
                        id="Group_119269"
                        data-name="Group 119269"
                        transform="translate(-1686.772 3417.292)"
                      >
                        <path
                          id="Union_17"
                          data-name="Union 17"
                          d="M0,17.416c0-4.222,3.7-7.647,8.27-7.647s8.274,3.425,8.274,7.647ZM3.625,4.354A4.508,4.508,0,0,1,8.27,0a4.51,4.51,0,0,1,4.649,4.354A4.51,4.51,0,0,1,8.27,8.708,4.508,4.508,0,0,1,3.625,4.354Z"
                          transform="translate(2500.501 -3166)"
                          fill="#636363"
                        />
                      </g>
                    </g>
                  </svg>

                  <span>User</span>
                </Link>
              </li>
            ) : null}
            <li>
              <Link to="/logout">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 52.719 64.78"
                >
                  <g
                    id="Group_120476"
                    data-name="Group 120476"
                    transform="translate(17773.359 -7521)"
                  >
                    <path
                      id="Path_100036"
                      data-name="Path 100036"
                      d="M33.64,27.61H57.21v-10H28.64a5,5,0,0,0-5,5V77.39a5,5,0,0,0,5,5H54.21v-10H33.64Z"
                      transform="translate(-17797 7503.39)"
                      fill="#636363"
                    />
                    <path
                      id="Path_100037"
                      data-name="Path 100037"
                      d="M53.12,39.64,58.09,45H42.27V55H58.09l-4.97,5.36,7.33,6.8L76.36,50,60.45,32.84Z"
                      transform="translate(-17797 7503.39)"
                      fill="#636363"
                    />
                  </g>
                </svg>

                <span>Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};
export default withRouter(withTranslation()(SidebarContent));
