import React from "react";
import { Modal, ModalBody } from "reactstrap";

interface props {
  show: boolean;
  onDeleteClick: any;
  onCloseClick: any;
}

const DeleteModal = ({ show, onDeleteClick, onCloseClick }: props) => {
  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      onClosed={onCloseClick}
    >
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
          <p className="text-muted font-size-16 mb-4">
            Are you sure you want to permanently erase the user.
          </p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onDeleteClick}
            >
              {" "}
              Delete Now
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default DeleteModal;
