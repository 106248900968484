import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Card, CardBody, Col, Form, Input, Label, Row } from "reactstrap";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import { ToastContainer, toast } from "react-toastify";
import withRouter from "Components/Common/withRouter";
import axiosApi from "../../helpers/axiosApi";
import logodark from "../../assets/images/logo-dark-white.svg";

const ResetPassword = (props) => {
  //meta title
  document.title =
    "Recover Password | Skote - React Admin & Dashboard Template";
  const baseUrl = process.env.REACT_APP_API_URL;
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values: any) => {
      // console.log("values", values);
    },
  });

  const [newpassword, setNewpassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [passerror, setPasserror] = useState(false);
  const [token, setToken] = useState("");

  const fetchToken = () => {
    let tokenget = window.location.search.slice(1).split("&")[0].split("=")[1];
    setToken(tokenget);
  };

  const handlepass = (e) => {
    setNewpassword(e.target.value);
  };
  const handleconfirmpass = (e) => {
    setConfirmpassword(e.target.value);
  };

  const passsubmit = (e) => {
    e.preventDefault();
    validation.handleSubmit();

    if (newpassword !== confirmpassword) {
      setPasserror(true);
    } else {
      axiosApi
        .post(
          `${baseUrl}/revive-password`,
          {
            newPassword: newpassword,
            confirmPassword: confirmpassword,
          },
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        )
        .then((result) => {
          toast.success("Password has successfully changed");
          props.router.navigate("/login");
          console.log(result);
        })
        .catch((error: any) => {
          console.log(error);
          toast.error(error?.response?.data?.error);
        });
    }

    return false;
  };

  useEffect(() => {
    fetchToken();
  }, []);
  return (
    <React.Fragment>
      <ToastContainer position="bottom-right" autoClose={2000} />
      <div className="account-pages newLoginPage">
        <div className="container-fluid">
          <Row>
            <Col md={6}>
              <div className="LoginLeft">
                <div className="logo">
                  <img src={logodark} alt="logo" />
                </div>
                <h1>
                  <span>Welcome to</span> <br></br> Transaction <br></br>
                  Monitoring Tool
                </h1>
              </div>
            </Col>
            <Col md={6}>
              <Card className="overflow-hidden loginBox">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4 login">
                        <h5> Reset Password</h5>
                      </div>
                    </Col>
                    <Col xs={5} className="align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="dashboard">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="p-2">
                    <Form className="form-horizontal">
                      <div className="mb-3">
                        <Label className="form-label">New Password</Label>
                        <Input
                          name="newpassword"
                          className="form-control"
                          placeholder="Password"
                          type="password"
                          onChange={handlepass}
                          value={newpassword}
                        />
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Confirm Password</Label>
                        <Input
                          name="ConfirmPassword"
                          className="form-control"
                          placeholder="Confirm Password"
                          type="password"
                          onChange={handleconfirmpass}
                          value={confirmpassword}
                        />
                      </div>
                      <div className="error">
                        {passerror ? <p>Passwords do NOT match!</p> : null}
                      </div>
                      <div className="text-center">
                        <button
                          className="btn btn-secondary w-md"
                          onClick={passsubmit}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="text-center mt-4">
                        Remember It ?{" "}
                        <Link to="/login" className="fw-medium text-primary">
                          {" "}
                          Sign In here
                        </Link>{" "}
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="allrights">© Rakuten Europe Bank</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ResetPassword);
